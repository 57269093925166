import { Box, Container, Heading, Text, useColorMode } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import ImgTextModule from "../components/elements/ImgTextModule";
import FadeInAnimation from "../components/UX/FadeInAnimation";
import MagicText from "../components/UX/MagicText";

const IndexPage = () => {
	const { setColorMode } = useColorMode();

	useEffect(() => {
		setColorMode("light");
	});

	return (
		<>
			<Helmet>
				<title>KDW | Personalmanagement und Managementberatung</title>
				<meta
					name="description"
					content="kdw HR bietet Kunden Dienstleistungen von der Personalvermittlung, über qualifizierte Arbeitnehmerüberlassung bis hin zu Management- und Fördermittelberatung im gesamten Personalmanagementbereich. Arbeitnehmer finden bei uns langfristige Karriereoptionen genauso wie neue Herausforderungen vornehmlich im Industrie-, Gewerbe- und Officesegment."
				/>
				<link rel="canonical" href="https:/kdw-hr.de" />
			</Helmet>

			{/* CONTAINER MAGICTEXT */}

			<ImgTextModule
				src={
					"240822_Businessman_Man_Silhouette_Dolly_In_By_Monster_Filmmakers_Artlist_HD.mp4"
				}
				delay={0.3}
				duration={0.5}
				threshold={0.3}
				initialY={10}
				initialX={10}
				video={true}
				h="85vh"
				mediaOpacity={0.8}
				bgColor="brand.gray.100">
				<Container variant="layoutContainer" mt={8}>
					<Box p={{ base: 0, lg: "8" }}>
						<FadeInAnimation
							threshold={0.4}
							delay={0.4}
							duration={0.7}
							initialX={0}
							initialY={-10}></FadeInAnimation>
						<FadeInAnimation
							threshold={0.4}
							delay={0.4}
							duration={0.4}
							initialX={0}
							initialY={-10}>
							<Text
								color="white"
								fontWeight="normal"
								fontSize={{ base: "md", lg: "lg" }}>
								Wir sind Mittler, Begleiter, Entwickler
							</Text>
							<Text
								color="brand.yellow"
								fontWeight="normal"
								fontSize={{ base: "4xl", lg: "6xl" }}>
								Über uns.
							</Text>
						</FadeInAnimation>

						<FadeInAnimation
							threshold={0.4}
							delay={1}
							duration={0.7}
							initialX={0}
							initialY={10}>
							<Text
								color="brand.gray.1000"
								mb={24}
								maxW={{ base: "100%", lg: "50%" }}
								fontSize={{ base: "3xl", lg: "4xl" }}
								lineHeight={"short"}>
								Neue Wege beschreitet man am Besten mit einem
								starken Team.
							</Text>
						</FadeInAnimation>
					</Box>
				</Container>
			</ImgTextModule>
			<Box bg="brand.gray.900">
				<Container variant="layoutContainer" py={32} px={8}>
					<FadeInAnimation
						threshold={0.1}
						delay={0}
						duration={0.7}
						initialX={0}
						initialY={10}>
						<Heading color="brand.violet" 	fontSize={{ base: "4xl", lg: "6xl" }}>
							Leitbild und Philosophie:
						</Heading>
					</FadeInAnimation>
					<FadeInAnimation
						threshold={0.1}
						delay={0.3}
						duration={0.7}
						initialX={0}
						initialY={10}>
						<Text my={8} fontSize={"4xl"} color="brand.green">
							Wir schaffen echte Partnerschaften zwischen Menschen
							und Unternehmen.
						</Text>
						<Text
							maxW={{ base: "100%", lg: "70%", xl: "50%" }}
							my={8}
							fontSize={"2xl"}
							color="brand.gray.700">
							Unser eigener Anspruch der Offenheit,
							Zuverlässigkeit, Wertschätzung und Leidenschaft
							findet sich in allem wieder, was wir tun. Das Ziel
							ist es, Vertrauen zu schaffen.
						</Text>
						<Text
							maxW={{ base: "100%", lg: "70%", xl: "50%" }}
							my={8}
							fontSize={"2xl"}
							color="brand.gray.700">
							Wir begeistern Mitarbeiter und Unternehmen mit einem
							Personalmanagement, neu gedacht, flexibel,
							nachhaltig, sicher und innovativ.
						</Text>
					</FadeInAnimation>
				</Container>
			</Box>
			<Container minW="100%" mt={0.5} p={0}>
				<MagicText
					text={
						"Wir wollen Karrieren menschlich gestalten helfen und schaffen echte Partnerschaften zwischen Menschen und Unternehmen."
					}
					quoteOrigin="Maik Wieting"
					fontSize={{ md: "6xl", base: "3xl" }}
					coloranimtext={"#00ffaa"}
					scrollLength={"250vh"}
					gap={{ md: 0, base: 0 }} //gap between words
					videoSrc="Maik1.mp4"
					scrollStartFactor={-0.1}
					scrollEndFactor={0.5}
					textColor={"brand.green"}
					showProgressCircle={true}
					progressColor={"brand.green"}
					showProgressLine={true}
					progressLineColor={"brand.violet"}
				/>
			</Container>
			<Box bg="brand.gray.900">
				<Container variant="layoutContainer" py={32}  px={8}>
					<FadeInAnimation
						threshold={0.1}
						delay={0}
						duration={0.7}
						initialX={0}
						initialY={10}>
						<Heading color="brand.violet" 	fontSize={{ base: "4xl", lg: "6xl" }}>

							Grundlage unseres Handelns:
						</Heading>
					</FadeInAnimation>
					<FadeInAnimation
						threshold={0.1}
						delay={0.3}
						duration={0.7}
						initialX={0}
						initialY={10}>
						<Text my={8} fontSize={"4xl"} color="brand.green">
							Zusammen sind wir stark.{" "}
						</Text>
						<Text
							maxW={{ base: "100%", lg: "70%", xl: "50%" }}
							my={8}
							fontSize={"2xl"}
							color="brand.gray.700">
							Werte wie Vertrauen, Wertschätzung, Offenheit und
							Zuverlässigkeit sind besonders in der heutigen Zeit
							nicht mehr nur ein Qualitätsmerkmal, sondern eine
							klare Notwendigkeit im Umgang mit Mitarbeitern,
							Partnern und Kunden und um somit erfolgreich zu
							sein. Bei uns sind dies nicht nur Worte in einer
							Präsentation.
						</Text>
						<Text
							maxW={{ base: "100%", lg: "70%", xl: "50%" }}
							my={8}
							fontSize={"2xl"}
							color="brand.gray.700">
							Jeder einzelne im Unternehmen trägt ein Teil zu
							diesem Erfolg bei, wir leben und wissen dies, und
							wir definieren uns über diese Werte. Auf allen
							Ebenen bieten wir unseren Kunden sowie Mitarbeitern
							durch Engagement und Nachhaltigkeit eine besondere
							Qualität und ein besonderes Umfeld, welche wir durch
							ein konsequentes Reflektieren unserer
							Arbeitsschritte und Prozesse erreichen und
							kontinuierlich optimieren.
						</Text>
					</FadeInAnimation>
				</Container>
			</Box>
			<Container minW="100%" mt={0.5} p={0}>
				<MagicText
					text={
						"Das Ziel ist Vertrauen. Unser eigener Anspruch von Offenheit, Wertschätzung und Zuverlässigkeit findet sich in allem wieder, was wir tun."
					}
					quoteOrigin="Nils Dykstra"
					fontSize={{ md: "6xl", base: "3xl" }}
					coloranimtext={"#ffaa00"}
					scrollLength={"250vh"}
					gap={{ md: 0, base: 0 }} //gap between words
					videoSrc="nils.mp4"
					scrollStartFactor={-0.1}
					scrollEndFactor={0.5}
					textColor={"brand.yellow"}
					showProgressCircle={true}
					progressColor={"brand.yellow"}
					showProgressLine={true}
					progressLineColor={"brand.violet"}
				/>
			</Container>
		</>
	);
};

export default IndexPage;
